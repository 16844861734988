import React, { useCallback, useEffect, useMemo, useRef } from "react";
import gsap from "gsap/all";
import queryString from "query-string";
import { useLocation } from "@reach/router";

// SVG asset for manipulation
import HoneycombHeroSVG from "./HoneycombHero.inline.svg";

const HoneycombAnimated = () => {
    // Query strings for debug actions
    const location = useLocation();
    const query = location.search ? queryString.parse(location.search) : false;

    // Ref for the wrapping element
    const wrapperRef = useRef();

    // GSAP timelines
    const mainTL = useRef();
    const dotTL = useRef();

    // GSAP context variable
    let ctx = useRef();

    // Collection of info to use for animations
    const collection = useMemo(() => {
        return {
            // Array of all logos, built in the useEffect() from the .logo class
            allSources: [],
            allDestinations: [],
            // Array of currently displayed logos, in their order
            current: [],
            // Maximum number of logos to display at once
            maxCurrent: 7,
            // List of the last X logos that were displayed
            running: [],
            // Maximum length of the running logos to keep
            maxRunning: 10,
            // List of the last X slots which were used
            runningSlots: [],
            // Maximum length of the running slots to keep
            maxRunningSlots: 5,
            // Coordinates for the slots
            coords: [
                { x: 135.5, y: 90.5 },
                { x: 407.5, y: 90.5 },
                { x: 90.5, y: 168 },
                { x: 452, y: 168 },
                { x: 45.5, y: 246 },
                { x: 135.25, y: 246 },
                { x: 408, y: 246 },
            ],
        };
    }, []);

    // Function to get random number between 0 and the max, not including the max
    const getRandWithMax = useCallback((max) => Math.floor(Math.random() * max), []);

    // Function which returns a new slot value which is not already in the "runningSlots" array
    const getNewSlot = useCallback(() => {
        // Define variable for the new slot value
        let newSlot = false;

        // Ensure the value is not in the "runningSlots" array
        while (newSlot === false || collection.runningSlots.includes(newSlot))
            newSlot = getRandWithMax(collection.maxCurrent);

        // Update the "runningSlots" array in the collection object
        collection.runningSlots.push(newSlot);

        // Trim the running slots array from the beginning if it's too long
        collection.runningSlots.length > collection.maxRunningSlots && collection.runningSlots.shift();

        // Return the new slot value
        return newSlot;
    }, [collection, getRandWithMax]);

    // Function to return a new logo ID from the collection, excluding items from "current" and "running"
    const getNewLogo = useCallback(
        (logoList) => {
            // Define variable for new logo ID value
            let randLogo;

            // If the logo is already in the current array, or part of the previous "running" array, get a new value
            while (!randLogo || collection.current.includes(randLogo) || collection.running.includes(randLogo)) {
                // Get a new random logo ID
                randLogo = collection[logoList][getRandWithMax(collection[logoList].length)];
            }

            // Update the collection state with the new "running" array
            collection.running.push(randLogo);

            // Trim the "running" array from the beginning if it's too long
            collection.running.length >= collection.maxRunning && collection.running.shift();

            // Return the new logo ID
            return randLogo;
        },
        [collection, getRandWithMax]
    );

    // Function which returns a timeline: chooses a random slot to fade out, and a random logo to fade-in
    const logoLoop = useCallback(() => {
        // Initiate the simulation timeline
        const simTL = gsap.timeline();

        // Determine a space to manipulate
        let newSlot = getNewSlot();

        // Get a new logo ID at random, taking "current" and "running" into account
        const newLogo = [0, 2, 4, 5].includes(newSlot) ? getNewLogo("sources") : getNewLogo("destinations");

        // Define the old logo as a var
        const oldLogo = collection.current[newSlot];

        // If old logo is present, fade it out
        oldLogo && simTL.to(oldLogo, { autoAlpha: 0, duration: 0.5 });

        // Update the "current" array with the new logo
        collection.current[newSlot] = newLogo;

        simTL
            // Move new logo to proper slot space
            .to(newLogo, {
                // Horizontal and vertical centering:
                xPercent: -50,
                yPercent: -50,
                // Position in the slot, immediately:
                x: collection.coords[newSlot].x,
                y: collection.coords[newSlot].y,
                duration: 0,
            })
            // Fade-in new logo
            .to(newLogo, { autoAlpha: 1, duration: 1 })
            // Control for end-timing of timeline, with self-referencing callback
            .to("body", { duration: 0, onComplete: () => mainTL.current.add(logoLoop()) }, "+=1");

        // Return the timeline
        return simTL;
    }, [collection, getNewLogo, getNewSlot]);

    // Function which returns a timeline: introduces specific logos into specific slots
    const curatedLogoFade = useCallback(
        (logoIds, slots = [], alpha = 1, stagger = 0, logoList = "sources") => {
            // Establish a timeline
            const curatedTL = gsap.timeline();

            // For each slot, move the logo to the proper position, and fade it in
            logoIds.forEach((logoId, index) => {
                // Corresponding slot to manipulate
                const slot = slots[index];

                // Timeline to move the logo to the proper position, and fade it in
                curatedTL
                    .to(
                        // Using a "to" instead of a "set" to ensure timing works with stagger
                        logoId,
                        {
                            x: collection.coords[slot].x,
                            y: collection.coords[slot].y,
                            xPercent: -50,
                            yPercent: -50,
                            // Immediately move to the proper position
                            duration: 0,
                        },
                        0
                    )
                    // Fade-in the logo
                    .to(logoId, { autoAlpha: alpha, duration: 1 }, stagger);

                // Update the "current" array to include the new logo in the corresponding array position
                collection.current[slot] = logoId;

                // Update the "running" array to include the new logo, at the end
                collection.running.push(logoId);
            });

            // Return the timeline
            return curatedTL;
        },
        [collection]
    );

    // Function which returns a timeline: loops moving elements (dots/circles) from start point to end point
    const dotMove = useCallback((id, coords = []) => {
        return gsap
            .timeline({
                delay: 1.75,
                repeat: -1,
                repeatDelay: 1.25,
            })
            .set(id, { autoAlpha: 0 })
            .to(id, { autoAlpha: 1, duration: 0.5 })
            .to(id, {
                x: coords[0],
                y: coords[1],
                duration: 2.5,
                ease: "Power1.easeInOut",
            })
            .to(id, { duration: 0.5 })
            .to(id, { autoAlpha: 0, duration: 1 });
    }, []);

    useEffect(() => {
        // Build out an empty current array of the same length as the maxCurrent value
        collection.current = new Array(collection.maxCurrent).fill(null);

        // Build out arrays for corresponding logo types from the logo Ids in the DOM of the SVG
        collection.sources = Array.from(document.querySelectorAll("#honeycombHero .logo.source")).map(
            (logo) => `#${logo.id}`
        );
        collection.destinations = Array.from(document.querySelectorAll("#honeycombHero .logo.destination")).map(
            (logo) => `#${logo.id}`
        );

        // GSAP context
        ctx.current = gsap.context(() => {
            // Timeline object
            mainTL.current = gsap.timeline({ delay: 0 });

            // Debug actions
            query?.timeScale && mainTL.current.timeScale(query.timeScale);

            // Hide any logos that are not in the intro array
            mainTL.current.to([...collection.sources, ...collection.destinations], { autoAlpha: 0, duration: 0 });

            // Initial slight delay
            mainTL.current.to("body", { duration: 0.33 });

            // Fade-in intro logos based on IDs of the logos to fade in on initial load
            mainTL.current.add(
                curatedLogoFade(
                    ["#logoGoogleAnalytics", "#logoPostgresql", "#logoSalesforce", "#logoMysql"],
                    [0, 2, 4, 5],
                    1,
                    "-=0.8"
                )
            );

            // Fade-in intro destinations
            mainTL.current.add(
                curatedLogoFade(
                    ["#logoSnowflake", "#logoAmazonRds", "#logoGoogleBigquery"],
                    [1, 3, 6],
                    1,
                    "-=0.8",
                    "destinations"
                ),
                "-=0.25"
            );

            // Additional timeline for circle animations
            dotTL.current = gsap.timeline();

            // Center-circles looping animations:
            dotTL.current.add(dotMove("#circleAnim1", [184, -104]));
            dotTL.current.add(dotMove("#circleAnim2", [184, 104]), "<");

            // Add slight delay
            mainTL.current.to("body", { duration: 0.75 });

            // Add continuous logo fade-rotation to timeline
            mainTL.current.add(logoLoop());

            // Pulse outline animation on Stitch logo
            ["#outline1", "#outline2"].forEach((id, index) => {
                mainTL.current.to(
                    id,
                    {
                        scale: 1.25,
                        autoAlpha: 0,
                        xPercent: -12.5,
                        yPercent: -12.5,
                        stroke: "#00BCD8",
                        duration: 4,
                        repeat: -1,
                    },
                    index * 2 // Slight offset between the two animations
                );
            });
        });

        // Debug actions: If a `timeScale` query-string parameter is present, set the timeScale of the main timeline
        query?.timeScale && mainTL.current.timeScale(query.timeScale);

        return () => {
            // Clear any GSAP context (timelines, etc)
            ctx.current && ctx.current.revert();

            // Kill the main timeline
            mainTL.current.kill();
        };
    }, [dotTL, dotMove, collection, curatedLogoFade, logoLoop, query]);

    return (
        <div ref={wrapperRef}>
            <HoneycombHeroSVG
                onClick={() => {
                    // If a timeScale query parameter is present, allow pausing of the animation
                    if (query?.timeScale) {
                        mainTL.current.timeScale() !== 0
                            ? mainTL.current.timeScale(0)
                            : mainTL.current.timeScale(query.timeScale);
                    }
                }}
                className="w-full md:w-9/12 h-auto mx-auto overflow-visible desktop:w-full"
            />
        </div>
    );
};

export default HoneycombAnimated;
